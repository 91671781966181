var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{staticClass:"bg-gray-login"},[(_vm.displayLogin)?_c('section',[_c('div',{staticClass:"box-form-login"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"px-4",attrs:{"sm":"12"}},[_c('div',{staticClass:"d-flex justify-content-center mb-4 pb-2"},[_c('div',{staticClass:"logo-login"},[_c('img',{attrs:{"src":require("@/assets/img/logo-2.svg"),"alt":""}})])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.onLogin)}}},[_c('text-input',{attrs:{"name":'email',"label":'Email',"type":'email',"rules":'required|email',"placeholder":'Type your email',"src":'mail.svg',"activeLable":true},model:{value:(_vm.auth.email),callback:function ($$v) {_vm.$set(_vm.auth, "email", $$v)},expression:"auth.email"}}),_c('text-input',{attrs:{"name":'password',"label":'Password',"type":'password',"rules":'required',"placeholder":'Type your password',"src":'lock.svg',"activeLable":true},model:{value:(_vm.auth.password),callback:function ($$v) {_vm.$set(_vm.auth, "password", $$v)},expression:"auth.password"}}),_c('button',{staticClass:"btn-custom btn-transparent cl-green w-100 mb-3",on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v(" Forgot Password? ")]),_c('button',{staticClass:"btn-custom btn-action w-100",attrs:{"type":"submit"}},[_vm._v(" Login ")])],1)}}],null,false,3166395955)})],1)],1)],1)],1)]):_vm._e(),(_vm.displayResetPassword)?_c('section',[_c('div',{staticClass:"box-form-login"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"px-4",attrs:{"sm":"12"}},[_c('div',{staticClass:"title-auth text-center mb-4"},[_vm._v("Verification")]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('div',{staticClass:"img-verifikasi"},[_c('img',{attrs:{"src":require("@/assets/img/padlock.svg"),"alt":""}})])]),_c('div',{staticClass:"desc-forgot text-center px-5 mb-4"},[_c('p',[_vm._v("We will sent you OTP code to reset your password")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.onResetEmail)}}},[_c('div',{staticClass:"spacing"},[_c('text-input',{attrs:{"name":'email',"type":'email',"rules":'required|email',"placeholder":'Enter email address',"activeLable":false},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('button',{staticClass:"btn-custom btn-action w-100",attrs:{"type":"submit"}},[_vm._v(" Send ")])])}}],null,false,1223544947)})],1)],1)],1)],1)]):_vm._e(),(_vm.displayOtp)?_c('section',[_c('div',{staticClass:"box-form-login"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"px-4",attrs:{"sm":"12"}},[_c('div',{staticClass:"title-auth text-center mb-4"},[_vm._v("Verification")]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('div',{staticClass:"img-verifikasi"},[_c('img',{attrs:{"src":require("@/assets/img/padlock.svg"),"alt":""}})])]),_c('div',{staticClass:"desc-forgot text-center px-4 mb-4"},[_c('p',[_vm._v(" Enter the verification code we just "),_c('br'),_vm._v(" sent you on your email address ")])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.onSendOtp)}}},[_c('div',{staticClass:"box-otp"},[_c('otp-input',{staticClass:"otp-container",attrs:{"length":4,"pattern":"[^0-9]+","ignorePattern":false,"fieldClass":"list-otp","size":32,"disabled":false,"password":true},on:{"valid":_vm.isTokenComplete},model:{value:(_vm.userToken),callback:function ($$v) {_vm.userToken=$$v},expression:"userToken"}}),_c('div',{staticClass:"resend text-center"},[_vm._v(" If you dont receive a code! "),_c('button',{staticClass:"btn-transparent cl-green"},[_vm._v("Resend")])])],1),_c('button',{staticClass:"btn-custom btn-action w-100",attrs:{"type":"submit","disabled":_vm.userToken === ''}},[_vm._v(" Send ")])])}}],null,false,2816121714)})],1)],1)],1)],1)]):_vm._e(),(_vm.displayChangePassword)?_c('section',[_c('div',{staticClass:"box-form-login"},[_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"px-4",attrs:{"sm":"12"}},[_c('div',{staticClass:"title-auth text-center mb-4"},[_vm._v("Change Password")]),_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('div',{staticClass:"img-verifikasi"},[_c('img',{attrs:{"src":require("@/assets/img/padlock.svg"),"alt":""}})])]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
return _c('b-form',{on:{"submit":function($event){$event.preventDefault();validate().then(_vm.onSavePassword)}}},[_c('div',{staticClass:"box-otp"},[_c('b-form-group',{staticClass:"mb-3"},[_c('div',{staticClass:"label-changepass"},[_vm._v("New Password")]),_c('b-form-input',{staticClass:"txt-password",attrs:{"type":"password"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1),_c('b-form-group',[_c('div',{staticClass:"label-changepass"},[_vm._v("Confirm Password")]),_c('b-form-input',{staticClass:"txt-password",attrs:{"type":"password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)],1),_c('button',{staticClass:"btn-custom btn-action w-100",attrs:{"type":"submit"}},[_vm._v(" Save ")])])}}],null,false,4202511434)})],1)],1)],1)],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }