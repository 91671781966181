/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosRequestConfig } from "axios";

const axios = Axios.create();

axios.interceptors.request.use((req) => {
  req.baseURL = process.env.VUE_APP_SALUTARIA_API_URL + "/api/ticket";
  req.headers.authorization = `Bearer ${localStorage.authToken}`;
  return req;
});
export default {
  getMyTask() {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/mytask",
    };
    return axios(options);
  },
  getMyTaskByDate(date: string | null) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/mytask",
      params: {
        date: date,
      },
    };
    return axios(options);
  },
  getDetailTask(idTicket: string | null) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/" + idTicket + "/detail",
    };
    return axios(options);
  },
  getListProgress(idTicket: string | null) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/" + idTicket + "/progress",
    };
    return axios(options);
  },
  getMyTicket() {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/myticket",
    };
    return axios(options);
  },
  getMyTicketByDate(date: string | null) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/myticket",
      params: {
        date: date,
      },
    };
    return axios(options);
  },
};
