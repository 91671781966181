import auth from "./auth";
import user from "./user";
import ticket from "./ticket";
import options from "./options";
export default {
  auth,
  user,
  ticket,
  options,
};
