export default class AuthModel {
  email: string;
  password: string;
  firebase_token: string;
  constructor() {
    this.email = "";
    this.password = "";
    this.firebase_token = "000";
  }
}
