










import Vue from "vue";
import Component from "vue-class-component";
import STHeader from "./header/Header.vue";
import { UserModule } from "@/store/modules/user";
import router from "@/router";
@Component({
  components: {
    STHeader,
  },
})
export default class Layout extends Vue {
  login = false;
  mounted() {
    this.checkLogin();
  }
  checkLogin() {
    if (this.getToken()) {
      this.login = true;
    } else {
      this.login = false;
      UserModule.Logout().then(() => {
        router.push({ name: "Auth" });
      });
    }
  }
  getToken() {
    return localStorage.getItem("authToken");
  }
}
