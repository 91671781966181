import GroupModel from "@/models/GroupModel";
export default class UserModel {
  user_id: number | null;
  email: string | null;
  phone: string | null;
  firstname: string | null;
  lastname: string | null;
  access_group_id: string | null;
  accessgroup: GroupModel[];
  constructor() {
    this.user_id = null;
    this.email = null;
    this.phone = null;
    this.firstname = null;
    this.lastname = null;
    this.access_group_id = null;
    this.accessgroup = [];
  }
}
