/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosRequestConfig } from "axios";

const axios = Axios.create();

axios.interceptors.request.use((req) => {
  req.baseURL = process.env.VUE_APP_SALUTARIA_API_URL + "/api";
  req.headers.authorization = `Bearer ${localStorage.authToken}`;
  return req;
});
export default {
  getCategories() {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/options/categories",
    };
    return axios(options);
  },
  getUrgencyLevel() {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/options/urgency",
    };
    return axios(options);
  },
  getMachine(keyword: string | null) {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/machine/search",
      params: {
        q: keyword,
      },
    };
    return axios(options);
  },
};
