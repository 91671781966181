























import Vue from "vue";
import Component from "vue-class-component";
import { ValidationProvider } from "vee-validate";
import { Prop, Model, Emit } from "vue-property-decorator";
@Component({
  components: {
    ValidationProvider,
  },
})
export default class TextInput extends Vue {
  @Model("input", { type: String }) value!: string;
  @Prop() rules!: string;
  @Prop() name!: string;
  @Prop() type!: string;
  @Prop() placeholder!: string;
  @Prop() label!: string;
  @Prop() src!: string;
  @Prop() alt!: string;
  @Prop() activeLable!: boolean;

  modValue: string = "";

  get computedValue() {
    if (this.value) {
      this.modValue = this.value;
    }
    return this.modValue;
  }

  @Emit()
  input() {
    return this.modValue ? this.modValue : "";
  }
}
