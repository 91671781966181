/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Axios, { AxiosRequestConfig } from "axios";
import UserModel from "@/models/UserModel";

const axios = Axios.create();

axios.interceptors.request.use((req) => {
  req.baseURL = process.env.VUE_APP_SALUTARIA_API_URL + "/api/user";
  req.headers.authorization = `Bearer ${localStorage.authToken}`;
  return req;
});
export default {
  getUserDetail() {
    const options: AxiosRequestConfig = {
      method: "get",
      url: "/profile",
    };
    return axios(options);
  },
  updateUser(payload: UserModel) {
    const options: AxiosRequestConfig = {
      method: "post",
      url: "/profile",
      data: {
        firstname: payload.firstname,
        lastname: payload.lastname,
        phone: payload.phone,
      },
    };
    return axios(options);
  },
};
