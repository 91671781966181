import store from "@/store";
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
// import AuthService from "@/services/ AuthService";
import api from "@/api";
import AuthModel from "@/models/AuthModel";
import UserModel from "@/models/UserModel";
// import utils from "@/utils";
// const storedUser = localStorage.getItem("user");
export interface IUserState {
  authToken: string;
  userId: string;
  userDetail: UserModel;
}
@Module({ dynamic: true, store, name: "user" })
class User extends VuexModule {
  // utils = utils;
  public authToken: string = localStorage.authToken || "";
  public userDetail: UserModel = new UserModel();

  @Mutation
  private SET_AUTH_TOKEN(authToken: string) {
    this.authToken = authToken;
  }

  @Mutation
  private SET_USER_DETAIL(userDetail: UserModel) {
    this.userDetail = userDetail;
  }

  @Action({ rawError: true })
  public Login(payload: AuthModel) {
    return new Promise((resolve, reject) => {
      api.auth
        .login(payload.email, payload.password, payload.firebase_token)
        .then((response) => {
          if (response.data.code === 200) {
            const authToken: string = response.data.data.jwt_token;
            localStorage.setItem("authToken", authToken);
            this.SET_AUTH_TOKEN(authToken);
            resolve(response);
          } else {
            this.Logout();
            reject(new Error("Login tidak berhasil"));
          }
        })
        .catch((err) => {
          this.Logout();
          reject(err);
        });
    });
  }
  @Action({ rawError: true })
  public LoadProfileUser() {
    return new Promise((resolve, reject) => {
      if (localStorage.authToken) {
        api.user.getUserDetail().then((response) => {
          if (response.data.code === 200) {
            this.SET_USER_DETAIL(response.data.data);
            resolve(response);
          } else {
            reject(new Error("Detail user tidak ditemukan"));
            this.Logout();
          }
        });
      } else {
        reject(new Error("Token Unavailable"));
        this.Logout();
      }
    });
  }

  @Action({ rawError: true })
  public Logout() {
    return new Promise<void>((resolve) => {
      localStorage.removeItem("authToken");
      this.SET_AUTH_TOKEN("");
      this.SET_USER_DETAIL(new UserModel());
      resolve();
    });
  }
}

export const UserModule = getModule(User);
