import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import { UserModule } from "@/store/modules/user";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.authToken) {
      UserModule.LoadProfileUser()
        .then(() => {
          next();
        })
        .catch(() => {
          UserModule.Logout().then(() => {
            next({ path: "/auth" });
          });
        });
    } else {
      UserModule.Logout().then(() => {
        next({ path: "/auth" });
      });
    }
  } else {
    if (UserModule.authToken && to.path === "/auth") {
      next({ path: "/home" });
    } else {
      next();
    }
  }
});

export default router;
