



































































































































































































import Vue from "vue";
import { BContainer, BForm, BFormGroup } from "bootstrap-vue";
import { Component } from "vue-property-decorator";
import AuthModel from "@/models/AuthModel";
import { UserModule } from "@/store/modules/user";
import TextInput from "@/components/input/TextInput.vue";
import router from "@/router";
import api from "@/api";
import { ValidationObserver, ValidationProvider } from "vee-validate";
@Component({
  components: {
    BContainer,
    TextInput,
    BForm,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Auth extends Vue {
  userToken: string | null = null;
  email: string | null = null;
  // password: string | null = null;
  // firebase_token: string | null = "000";
  displayLogin = true;
  displayResetPassword = false;
  displayOtp = false;
  displayChangePassword = false;
  errorDialogState = false;
  auth: AuthModel = new AuthModel();

  onLogin() {
    this.errorDialogState = false;
    if (this.auth.email && this.auth.password && this.auth.firebase_token) {
      UserModule.Login(this.auth)
        .then(() => {
          router.push({ name: "home" });
        })
        .catch(() => {
          this.errorDialogState = true;
        });
    }
    // this.$router.push({ name: "home" });
  }
  logout() {
    this.errorDialogState = false;
    UserModule.Logout().then(() => {
      router.push({ name: "Splash" });
      // window.location.href = `${process.env.VUE_APP_SSO_URL}/logout`;
    });
  }
  forgotPassword() {
    this.displayLogin = false;
    this.displayResetPassword = true;
    this.displayOtp = false;
    this.displayChangePassword = false;
  }
  onResetEmail() {
    return new Promise((resolve, reject) => {
      if (this.email) {
        api.auth
          .forgetPassword(this.email)
          .then((response) => {
            this.displayLogin = false;
            this.displayResetPassword = false;
            this.displayOtp = true;
            this.displayChangePassword = false;
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  }
  onSendOtp() {
    this.displayLogin = false;
    this.displayResetPassword = false;
    this.displayOtp = false;
    this.displayChangePassword = true;
  }
  onSavePassword() {
    this.displayLogin = true;
    this.displayResetPassword = false;
    this.displayOtp = false;
    this.displayChangePassword = false;
  }
}
