import { RouteConfig } from "vue-router";
import Layout from "@/views/layout/Layout.vue";
import Splash from "@/views/Splash.vue";
import Auth from "@/views/pages/auth/Auth.vue";
import LayoutList from "@/views/layout/LayoutList.vue";

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Splash",
    component: Splash,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/home",
    redirect: "/home",
    component: Layout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/pages/Home.vue"),
      },
      {
        path: "/create-ticket",
        name: "create-ticket",
        component: () =>
          import(
            /* webpackChunkName: "create-ticket" */ "@/views/pages/CreateTicket.vue"
          ),
      },
      {
        path: "/about",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About.vue"),
      },
    ],
  },
  {
    path: "/ticket",
    redirect: "/ticket",
    component: LayoutList,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/ticket",
        name: "ticket",
        component: () =>
          import(
            /* webpackChunkName: "ticket" */ "@/views/pages/ListTicket.vue"
          ),
      },
      {
        path: "/notification",
        name: "notification",
        component: () =>
          import(
            /* webpackChunkName: "notification" */ "@/views/pages/ListNotification.vue"
          ),
      },
      {
        path: "/close-ticket",
        name: "close-ticket",
        component: () =>
          import(
            /* webpackChunkName: "close-ticket" */ "@/views/pages/ListCloseTicket.vue"
          ),
      },
      {
        path: "/detail-ticket/:id",
        name: "detail-ticket",
        component: () =>
          import(
            /* webpackChunkName: "detail-ticket" */ "@/views/pages/DetailTicket.vue"
          ),
      },
      {
        path: "/chat",
        name: "chat",
        component: () =>
          import(/* webpackChunkName: "chat" */ "@/views/pages/Chat.vue"),
      },
      {
        path: "/progress/:id",
        name: "progress",
        component: () =>
          import(
            /* webpackChunkName: "progress" */ "@/views/pages/Progress.vue"
          ),
      },
      // {
      //   path: "/progress",
      //   name: "progress",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "progress" */ "@/views/pages/Progress.vue"
      //     ),
      // },
      {
        path: "/profile",
        name: "profile",
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/pages/Profile.vue"),
      },
      {
        path: "/form-reject",
        name: "form-reject",
        component: () =>
          import(
            /* webpackChunkName: "form-reject" */ "@/views/pages/FormReject.vue"
          ),
      },
      {
        path: "/faq",
        name: "faq",
        component: () =>
          import(/* webpackChunkName: "faq" */ "@/views/pages/Faq.vue"),
      },
    ],
  },
];

export default routes;
