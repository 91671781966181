import Axios, { AxiosRequestConfig } from "axios";
// import AuthModel from "@/models/AuthModel";
const axios = Axios.create();
axios.interceptors.request.use((req) => {
  req.baseURL = process.env.VUE_APP_SALUTARIA_API_URL + "/api/auth";
  return req;
});

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  login(email: string, password: string, firebase_token: string) {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("firebase_token", firebase_token);
    const options: AxiosRequestConfig = {
      method: "post",
      url: "/login",
      data: formData,
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
      },
    };
    return axios(options);
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  forgetPassword(email: string) {
    const formData = new FormData();
    formData.append("email", email);
    const options: AxiosRequestConfig = {
      method: "post",
      url: "/forget",
      data: formData,
    };
    return axios(options);
  },
};
